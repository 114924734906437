import { combineReducers } from "redux";
import { boardDataReducer } from "./boardDataReducer";
import { columnsReducer } from "./columnsReducer";
import { environmentReducer } from "./environmentReducer";
import { screenReducer } from "./screenReducer";

export const rootReducer = combineReducers({
  environment: environmentReducer,
  columns: columnsReducer,
  screen: screenReducer,
  boardData: boardDataReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
