export type BoardDataAction =
  | GetBoardDataInitAction
  | GetBoardDataSuccessAction
  | GetBoardDataErrorAction;

export interface BoardDataState {
  loading: boolean;
  boardData: null | any;
  error: null | string;
}

export enum BoardDataActionTypes {
  GET_BOARD_DATA_INIT = "GET_BOARD_DATA_INIT",
  GET_BOARD_DATA_SUCCESS = "GET_BOARD_DATA_SUCCESS",
  GET_BOARD_DATA_ERROR = "GET_BOARD_DATA_ERROR",
  SET_LOCATION_COLUMN = "SET_LOCATION_COLUMN",
}

interface GetBoardDataInitAction {
  type: BoardDataActionTypes.GET_BOARD_DATA_INIT;
}

interface GetBoardDataSuccessAction {
  type: BoardDataActionTypes.GET_BOARD_DATA_SUCCESS;
  payload: any;
}

interface GetBoardDataErrorAction {
  type: BoardDataActionTypes.GET_BOARD_DATA_ERROR;
  payload: string;
}
