import { useEffect } from "react";
import mondaySdk from "monday-sdk-js";
import { setTheme } from "./services/theme-service";
import { useDispatch } from "react-redux";
import { getContext } from "./store/action-creators/environment";
import { useTypedSelector } from "./hooks/useTypedSelector";
import { SCREEN_NAMES } from "./constants";
import { ErrorScreen, MapScreen, StartScreen } from "./components";
import "monday-ui-react-core/dist/main.css";
import "./App.scss";

const monday = mondaySdk();

function App() {
  const dispatch = useDispatch();
  const { screen } = useTypedSelector((state) => state.screen);

  useEffect(() => {
    monday.listen("context", (res) => {
      const { theme } = res.data;
      setTheme(theme);
    });
  }, []);

  useEffect(() => {
    dispatch(getContext());
  }, [dispatch]);

  return (
    <div className="App">
      {screen === SCREEN_NAMES.START_SCREEN && <StartScreen />}
      {screen === SCREEN_NAMES.MAP_SCREEN && <MapScreen />}
      {screen === SCREEN_NAMES.ERROR_SCREEN && <ErrorScreen />}
    </div>
  );
}

export default App;
