import mondaySdk from "monday-sdk-js";
import { Dispatch } from "redux";
import {
  EnvironmentAction,
  EnvironmentActionTypes,
} from "../../types/environment";

const monday = mondaySdk();

export const getContext = () => {
  return async (dispatch: Dispatch<EnvironmentAction>) => {
    try {
      dispatch({ type: EnvironmentActionTypes.GET_CONTEXT });
      const { data } = await monday.get("context");
      const { boardId, theme } = data;
      dispatch({
        type: EnvironmentActionTypes.GET_CONTEXT_SUCCESS,
        payload: { boardId, theme },
      });
    } catch (error) {
      dispatch({
        type: EnvironmentActionTypes.GET_CONTEXT_ERROR,
        payload: "Failed to get Monday context",
      });
    }
  };
};
