import {
  EnvironmentAction,
  EnvironmentActionTypes,
  EnvironmentState,
} from "../../types/environment";

const initialState: EnvironmentState = {
  loading: false,
  data: null,
  error: null,
};

export const environmentReducer = (
  state = initialState,
  action: EnvironmentAction
): EnvironmentState => {
  switch (action.type) {
    case EnvironmentActionTypes.GET_CONTEXT:
      return { ...state, loading: true };
    case EnvironmentActionTypes.GET_CONTEXT_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case EnvironmentActionTypes.GET_CONTEXT_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
