export const SCREEN_NAMES = {
  MAP_SCREEN: "MAP_SCREEN",
  START_SCREEN: "START_SCREEN",
  ERROR_SCREEN: "ERROR_SCREEN",
};

export const POLLUTANT_NORMA = {
  goodQuality: {
    O3: 54,
    CO: 4.4,
    SO2: 35,
    NO2: 53,
    PM10: 54,
    PM25: 12,
  },
  moderateQuality: {
    O3: 70,
    CO: 9.4,
    SO2: 75,
    NO2: 100,
    PM10: 154,
    PM25: 35.4,
  },
  unhealthyQuality: {
    O3: 105,
    CO: 15.4,
    SO2: 304,
    NO2: 649,
    PM10: 354,
    PM25: 150.4,
  },
};

const defaultOptions = {
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillOpacity: 0.5,
};

export const CIRCLE_OPTIONS = {
  goodQuality: {
    ...defaultOptions,
    zIndex: 3,
    stroke: "#8BC34A",
    fill: "#8BC34A",
  },
  moderateQuality: {
    ...defaultOptions,
    zIndex: 2,
    stroke: "#FBC02D",
    fill: "#FBC02D",
  },
  unhealthyQuality: {
    ...defaultOptions,
    zIndex: 1,
    stroke: "#FF5252",
    fill: "#FF5252",
  },
};

export const POLLUTANTS_FIELDS = ["CO", "O3", "SO2", "NO2", "PM10", "PM2.5"];

export const POLLUTANTS_UNITS = {
  O3: "ppb",
  CO: "ppm",
  SO2: "ppb",
  NO2: "ppb",
  PM10: "μg/m^3",
  "PM2.5": "μg/m^3",
};
