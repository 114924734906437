export type EnvironmentAction =
  | GetContextAction
  | GetContextSuccessAction
  | GetContextErrorAction;

export interface EnvironmentState {
  loading: boolean;
  data: null | EnvironmentData;
  error: null | string;
}

export enum EnvironmentActionTypes {
  UPDATE = "UPDATE",
  GET_CONTEXT = "GET_CONTEXT",
  GET_CONTEXT_SUCCESS = "GET_CONTEXT_SUCCESS",
  GET_CONTEXT_ERROR = "GET_CONTEXT_ERROR",
}

type EnvironmentData = {
  boardId: number;
  theme: string;
};

interface GetContextAction {
  type: EnvironmentActionTypes.GET_CONTEXT;
}

interface GetContextSuccessAction {
  type: EnvironmentActionTypes.GET_CONTEXT_SUCCESS;
  payload: EnvironmentData;
}

interface GetContextErrorAction {
  type: EnvironmentActionTypes.GET_CONTEXT_ERROR;
  payload: string;
}
