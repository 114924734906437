export function setTheme(themeName: string) {
  const availableThemes = ["light", "dark", "black"];

  themeName = availableThemes.includes(themeName)
    ? themeName
    : availableThemes[0];

  document.documentElement.className = "";
  document.documentElement.classList.add(`${themeName}-app-theme`);
}
