import { SCREEN_NAMES } from "../../constants";
import { ScreenAction, ScreenActionTypes } from "../../types/screens";

export const switchScreen: (screen: string) => ScreenAction = (screen) => {
  if (screen === SCREEN_NAMES.MAP_SCREEN) {
    return { type: ScreenActionTypes.SWITCH_TO_MAP_SCREEN };
  }

  if (screen === SCREEN_NAMES.ERROR_SCREEN) {
    return { type: ScreenActionTypes.SWITCH_TO_ERROR_SCREEN };
  }

  return { type: ScreenActionTypes.SWITCH_TO_START_SCREEN };
};

export const setErrorScreenMessage = (message: string) => ({
  type: ScreenActionTypes.SET_ERROR_SCREEN_MESSAGE,
  payload: message,
});
