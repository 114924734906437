import {
  ColumnsAction,
  ColumnsActionTypes,
  ColumnsState,
} from "../../types/columns";

const initialState: ColumnsState = {
  loading: false,
  columns: null,
  error: null,
  locationColumn: null,
  pollutantsColumns: null,
};

export const columnsReducer = (
  state = initialState,
  action: ColumnsAction
): ColumnsState => {
  switch (action.type) {
    case ColumnsActionTypes.GET_COLUMNS_INIT:
      return { ...state, loading: true };
    case ColumnsActionTypes.GET_COLUMNS_SUCCESS:
      return { ...state, loading: false, columns: action.payload };
    case ColumnsActionTypes.GET_COLUMNS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case ColumnsActionTypes.SET_LOCATION_COLUMN: {
      return { ...state, locationColumn: action.payload };
    }
    case ColumnsActionTypes.SET_POLLUTANTS_COLUMNS: {
      return { ...state, pollutantsColumns: action.payload };
    }
    default:
      return state;
  }
};
