export interface ScreenState {
  screen: string;
  errorScreenMessage: string | null;
}

export type ScreenAction =
  | SwitchToMapScreenAction
  | SwitchToStartScreenAction
  | SwitchToErrorScreenAction
  | SetErrorScreenMessageAction;

export enum ScreenActionTypes {
  SWITCH_TO_MAP_SCREEN = "SWITCH_TO_MAP_SCREEN",
  SWITCH_TO_START_SCREEN = "SWITCH_TO_START_SCREEN",
  SWITCH_TO_ERROR_SCREEN = "SWITCH_TO_ERROR_SCREEN",
  SET_ERROR_SCREEN_MESSAGE = "SET_ERROR_SCREEN_MESSAGE",
}

interface SwitchToMapScreenAction {
  type: ScreenActionTypes.SWITCH_TO_MAP_SCREEN;
}

interface SwitchToStartScreenAction {
  type: ScreenActionTypes.SWITCH_TO_START_SCREEN;
}

interface SwitchToErrorScreenAction {
  type: ScreenActionTypes.SWITCH_TO_ERROR_SCREEN;
}

interface SetErrorScreenMessageAction {
  type: ScreenActionTypes.SET_ERROR_SCREEN_MESSAGE;
  payload: string;
}
