import { Dispatch } from "react";
import { formColumnData } from "../../services/parser";
import { getBoardData } from "../../services/sdk-service";
import { BoardDataAction, BoardDataActionTypes } from "../../types/boardData";
import { RootState } from "../reducers";

export const getBoardDataAction = (
  boardId: number,
  locationColumnId: string
) => {
  return async (
    dispatch: Dispatch<BoardDataAction>,
    getState: () => RootState
  ) => {
    try {
      dispatch({ type: BoardDataActionTypes.GET_BOARD_DATA_INIT });
      const boardData = await getBoardData(boardId);
      const { pollutantsColumns, locationColumn } = getState().columns;
      const columns = boardData.reduce((arr: any, item: any) => {
        const itemColumns = item.column_values;
        for (const column of itemColumns) {
          if (column.id === locationColumnId && !column.value) {
            return arr;
          }
          continue;
        }
        arr.push(
          formColumnData(itemColumns, pollutantsColumns, locationColumn)
        );
        return arr;
      }, []);

      dispatch({
        type: BoardDataActionTypes.GET_BOARD_DATA_SUCCESS,
        payload: columns,
      });
    } catch (error: any) {
      console.log(error.message);

      dispatch({
        type: BoardDataActionTypes.GET_BOARD_DATA_ERROR,
        payload: error?.message || "Monday request failed",
      });
    }
  };
};
