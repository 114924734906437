import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

export async function getContext() {
  const { data } = await monday.get("context");
  const { boardId, theme } = data;
  return { boardId, theme };
}

export async function getBoardData(boardId: number) {
  const query = `
      query($boardId: [Int]) {
        boards(ids: $boardId) {
          items {
            id
            column_values {
              id
              text
              type
              value
              title
            }
          }
        }
      }`;
  const variables = { boardId };
  const response = await monday.api(query, { variables });
  return response.data.boards[0]?.items || [];
}

export async function getColumnsInfo(boardId: number) {
  const query = `
      query($boardId: [Int]) {
        boards(ids: $boardId) {
            columns{
                id
                title
            }
        }
      }`;
  const variables = { boardId };
  const response = await monday.api(query, { variables });
  return response.data.boards[0].columns;
}
