import { useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ButtonGroup } from "monday-ui-react-core";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getBoardDataAction } from "../../store/action-creators/boardData";
import {
  setErrorScreenMessage,
  switchScreen,
} from "../../store/action-creators/screens";
import { SCREEN_NAMES } from "../../constants";
import { Loader } from "../Loader/Loader";
import { Map } from "../Map/Map";
import "./Map.scss";

export const MapScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { data } = useTypedSelector((state) => state.environment);
  const { locationColumn, columns, pollutantsColumns } = useTypedSelector(
    (state) => state.columns
  );
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
  });
  const { loading, error } = useTypedSelector((state) => state.boardData);
  const [selectedPollutant, setSelectedPollutant] = useState("");

  useEffect(() => {
    if (!data || !locationColumn || !pollutantsColumns) return;
    dispatch(getBoardDataAction(data?.boardId, locationColumn));
  }, [dispatch, data, locationColumn, pollutantsColumns]);

  if (loadError || error) {
    dispatch(setErrorScreenMessage(error ?? "Google Maps Error"));
    dispatch(switchScreen(SCREEN_NAMES.ERROR_SCREEN));
  }

  if (!isLoaded || loading) {
    return <Loader />;
  }

  return (
    <div className="map">
      {columns && (
        <ButtonGroup
          onSelect={(e: any) => {
            if (selectedPollutant === e) {
              setSelectedPollutant("");
              return;
            }
            setSelectedPollutant(e);
          }}
          options={columns.reduce((arr: any, column: any) => {
            if (!pollutantsColumns[column.id]) return arr;
            return [
              ...arr,
              {
                text: pollutantsColumns[column.id],
                value: pollutantsColumns[column.id],
              },
            ];
          }, [])}
          value={selectedPollutant}
          className="btn-group"
        />
      )}
      <Map selectedPollutant={selectedPollutant} />
    </div>
  );
};
