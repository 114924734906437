import {
  BoardDataAction,
  BoardDataActionTypes,
  BoardDataState,
} from "../../types/boardData";

const initialState: BoardDataState = {
  loading: false,
  boardData: null,
  error: null,
};

export const boardDataReducer = (
  state = initialState,
  action: BoardDataAction
): BoardDataState => {
  switch (action.type) {
    case BoardDataActionTypes.GET_BOARD_DATA_INIT:
      return { ...state, loading: true };
    case BoardDataActionTypes.GET_BOARD_DATA_SUCCESS:
      return { ...state, loading: false, boardData: action.payload };
    case BoardDataActionTypes.GET_BOARD_DATA_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
