export type ColumnsAction =
  | GetColumnsAction
  | GetColumnsSuccessAction
  | GetColumnsErrorAction
  | SetLocationColumnAction
  | SetPollutantsColumnsAction;

export interface ColumnsState {
  loading: boolean;
  columns: null | Column[];
  error: null | string;
  locationColumn: any;
  pollutantsColumns: any;
}

export enum ColumnsActionTypes {
  GET_COLUMNS_INIT = "GET_COLUMNS_INIT",
  GET_COLUMNS_SUCCESS = "GET_COLUMNS_SUCCESS",
  GET_COLUMNS_ERROR = "GET_COLUMNS_ERROR",
  SET_LOCATION_COLUMN = "SET_LOCATION_COLUMN",
  SET_POLLUTANTS_COLUMNS = "SET_POLLUTANTS_COLUMNS",
}

type Column = {
  id: string;
  title: string;
};

interface GetColumnsAction {
  type: ColumnsActionTypes.GET_COLUMNS_INIT;
}

interface GetColumnsSuccessAction {
  type: ColumnsActionTypes.GET_COLUMNS_SUCCESS;
  payload: Column[];
}

interface GetColumnsErrorAction {
  type: ColumnsActionTypes.GET_COLUMNS_ERROR;
  payload: string;
}

interface SetLocationColumnAction {
  type: ColumnsActionTypes.SET_LOCATION_COLUMN;
  payload: string;
}

interface SetPollutantsColumnsAction {
  type: ColumnsActionTypes.SET_POLLUTANTS_COLUMNS;
  payload: any;
}
