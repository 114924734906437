import { SCREEN_NAMES } from "../../constants";
import {
  ScreenAction,
  ScreenActionTypes,
  ScreenState,
} from "../../types/screens";

const initialState: ScreenState = {
  screen: SCREEN_NAMES.START_SCREEN,
  errorScreenMessage: null,
};

export const screenReducer = (
  state = initialState,
  action: ScreenAction
): ScreenState => {
  switch (action.type) {
    case ScreenActionTypes.SWITCH_TO_MAP_SCREEN:
      return { ...state, screen: SCREEN_NAMES.MAP_SCREEN };

    case ScreenActionTypes.SWITCH_TO_START_SCREEN:
      return { ...state, screen: SCREEN_NAMES.START_SCREEN };

    case ScreenActionTypes.SWITCH_TO_ERROR_SCREEN:
      return { ...state, screen: SCREEN_NAMES.ERROR_SCREEN };

    case ScreenActionTypes.SET_ERROR_SCREEN_MESSAGE:
      return { ...state, errorScreenMessage: action.payload };
    default:
      return state;
  }
};
