import { AttentionBox } from "monday-ui-react-core";
import { useTypedSelector } from "../../hooks/useTypedSelector";

export const ErrorScreen: React.FC = () => {
  const { errorScreenMessage } = useTypedSelector((state) => state.screen);

  if (!errorScreenMessage) return null;

  return (
    <AttentionBox
      title="Error"
      text={errorScreenMessage}
      type={"danger"}
      className="monday-style-attention-box_box"
    />
  );
};
