import {
  GoogleMap,
  InfoWindow,
  Marker,
  OverlayView,
} from "@react-google-maps/api";
import { useState } from "react";
import _ from "lodash";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { CIRCLE_OPTIONS, POLLUTANTS_UNITS } from "../../constants";
import { getPollutantCategory } from "../../services/parser";

type SelectedMarker = {
  lat: number;
  lng: number;
  info: any;
};

interface MapProps {
  selectedPollutant: string;
}

const center = { lat: 51.507351, lng: -0.127758 };

export const Map: React.FC<MapProps> = ({ selectedPollutant }) => {
  const { boardData } = useTypedSelector((state) => state.boardData);
  const { locationColumn } = useTypedSelector((state) => state.columns);
  const [selectedMarker, setSelectedMarker] = useState<SelectedMarker | null>(
    null
  );

  return (
    <GoogleMap
      mapContainerStyle={{
        width: "100vw",
        height: "100vh",
      }}
      zoom={4}
      options={{
        zoomControl: true,
      }}
      center={center}
    >
      {boardData &&
        boardData.map((column: any, i: number) => {
          const { lat, lng } = column[locationColumn];
          const pollutants = _.omit(column, [locationColumn]);
          return !selectedPollutant ? (
            <Marker
              key={`${lat}-${lng}-${i}`}
              position={{ lat, lng }}
              onClick={() =>
                setSelectedMarker({
                  lat,
                  lng,
                  info: pollutants,
                })
              }
            />
          ) : (
            <OverlayView
              key={i}
              position={{ lat, lng }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={(width, height) => ({
                x: -(width / 2),
                y: -(height / 2),
              })}
            >
              <svg
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                height="50"
                width="50"
              >
                <circle
                  cx="50"
                  cy="50"
                  r="50"
                  style={
                    CIRCLE_OPTIONS[
                      // @ts-ignore
                      getPollutantCategory(
                        selectedPollutant,
                        column[selectedPollutant]
                      )
                    ]
                  }
                />
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  fill="white"
                  fontSize="30px"
                  dy=".3em"
                >
                  {column[selectedPollutant]}
                </text>
              </svg>
            </OverlayView>
          );
        })}
      {selectedMarker ? (
        <InfoWindow
          position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div>
            {Object.entries(selectedMarker.info).map(([name, value]) => (
              <div key={name}>
                {name}: {value}{" "}
                {POLLUTANTS_UNITS[name as keyof typeof POLLUTANTS_UNITS]}
              </div>
            ))}
          </div>
        </InfoWindow>
      ) : null}
    </GoogleMap>
  );
};
