import { Dispatch } from "react";
import { getColumnsInfo } from "../../services/sdk-service";
import { ColumnsAction, ColumnsActionTypes } from "../../types/columns";

export const getColumns = (boardId: number) => {
  return async (dispatch: Dispatch<ColumnsAction>) => {
    try {
      dispatch({ type: ColumnsActionTypes.GET_COLUMNS_INIT });
      const columns = await getColumnsInfo(boardId);
      dispatch({
        type: ColumnsActionTypes.GET_COLUMNS_SUCCESS,
        payload: columns,
      });
    } catch (error: any) {
      console.log(error.message);

      dispatch({
        type: ColumnsActionTypes.GET_COLUMNS_ERROR,
        payload: error?.message || "Monday request failed",
      });
    }
  };
};

export const setLocationColumn = (id: string) => ({
  type: ColumnsActionTypes.SET_LOCATION_COLUMN,
  payload: id,
});

export const setPollutantsColumns = (pollutants: any) => ({
  type: ColumnsActionTypes.SET_POLLUTANTS_COLUMNS,
  payload: pollutants,
});
