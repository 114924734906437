import React, { useEffect, useState } from "react";
import { Dropdown } from "monday-ui-react-core";
import { Button } from "monday-ui-react-core";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import {
  getColumns,
  setLocationColumn,
  setPollutantsColumns,
} from "../../store/action-creators/columns";
import {
  setErrorScreenMessage,
  switchScreen,
} from "../../store/action-creators/screens";
import { POLLUTANTS_FIELDS, SCREEN_NAMES } from "../../constants";
import { Loader } from "../Loader/Loader";
import "./StartScreen.scss";
import { switchKeyValue } from "../../services/parser";

export const StartScreen: React.FC = () => {
  const dispatch = useDispatch();
  const {
    data,
    error: envError,
    loading: envLoading,
  } = useTypedSelector((state) => state.environment);
  const { columns, loading, error } = useTypedSelector(
    (state) => state.columns
  );
  const [selectedColumnId, setSelectedColumnId] = useState("");
  const [selectedPollutants, setSelectedPollutants] = useState({});

  useEffect(() => {
    if (!data) return;
    dispatch(getColumns(data.boardId));
  }, [dispatch, data]);

  const options = columns
    ? columns.map(({ title, id }: { title: string; id: string }) => ({
        value: title,
        label: title,
        id,
      }))
    : [];

  function handleClick() {
    if (!selectedColumnId) return;
    dispatch(setLocationColumn(selectedColumnId));
    dispatch(setPollutantsColumns(switchKeyValue(selectedPollutants)));
    dispatch(switchScreen(SCREEN_NAMES.MAP_SCREEN));
  }

  if (loading || envLoading) {
    return <Loader />;
  }

  if (error || envError) {
    dispatch(
      setErrorScreenMessage(error ?? envError ?? "Something went wrong")
    );
    dispatch(switchScreen(SCREEN_NAMES.ERROR_SCREEN));
  }

  return (
    <div className="start-screen">
      <h1 className="start-screen__title">
        Select Location and Pollutants Columns
      </h1>
      <div className="start-screen__column-list">
        <div className="start-screen__dropdowns">
          <div className="mapping-dropdown-container">
            <div className="mapping-dropdown__title">Location:</div>
            <Dropdown
              className="mapping-dropdown__dropdown"
              size={Dropdown.size.MEDIUM}
              options={options}
              placeholder="Click to select"
              onChange={(e: { id: React.SetStateAction<string> }) => {
                setSelectedColumnId(e?.id);
              }}
            />
          </div>
          {POLLUTANTS_FIELDS.map((pollutant, i) => (
            <div className="mapping-dropdown-container" key={i}>
              <div className="mapping-dropdown__title">{pollutant}:</div>
              <Dropdown
                className="mapping-dropdown__dropdown"
                size={Dropdown.size.MEDIUM}
                options={options}
                placeholder="Click to select"
                onChange={(e: any) => {
                  setSelectedPollutants((pollutants) => {
                    return {
                      ...pollutants,
                      [pollutant]: e,
                    };
                  });
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="start-screen__buid-btn-container">
        <div className="build-button">
          <Button
            className="build-button__btn"
            disabled={!selectedColumnId}
            size={Button.sizes.MEDIUM}
            onClick={handleClick}
          >
            Build
          </Button>
        </div>
      </div>
    </div>
  );
};
