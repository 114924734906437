import { POLLUTANT_NORMA } from "../constants";

export function formColumnData(
  columnValues: any,
  pollutants: any,
  locationColumn: string
) {
  let result = {};
  for (const column of columnValues) {
    if (!pollutants[column.id] && column.id !== locationColumn) continue;
    result = {
      ...result,
      [column.id === locationColumn ? column.id : pollutants[column.id]]:
        parseColumn(column, locationColumn),
    };
  }
  return result;
}

function parseColumn(column: any, locationColumn: string) {
  if (column.id === locationColumn) {
    return getLocationColumnValue(column);
  }
  return column.text;
}

export function getLocationColumnValue(column: any) {
  if (column.type === "text") {
    const [lat, lng] = column.text.trim().split(",");
    return {
      lat: Number(lat),
      lng: Number(lng),
    };
  }
  return parseLocationColumn(column.value);
}

function parseLocationColumn(value: any) {
  if (value === null) return "";
  const { lat, lng } = JSON.parse(value);
  return { lat: Number(lat), lng: Number(lng) };
}

export function getPollutantCategory(pol: string, value: number) {
  let res;
  for (const key in POLLUTANT_NORMA) {
    // @ts-ignore
    if (POLLUTANT_NORMA[key][pol] >= value) {
      res = key;
      break;
    }
    res = key;
  }
  return res;
}

export function switchKeyValue(obj: any) {
  let result: any = {};
  for (const key in obj) {
    if (!obj[key]) continue;
    result[obj[key].id] = key;
  }
  return result;
}
